import axios from "axios";
// import Api from "../lib/api";

const crypto = require("crypto");

/**
 * Retrieves a list of available customers.
 * Each customer object includes an ID, domains, an API endpoint, and optional background images/logos.
 * @returns {Array} List of customer objects.
 */
async function getAvailableCustomers() {
  const customersEndpoint = process.env.REACT_APP_GET_CUSTOMERS_ENDPOINT;
  const clientAPIKey = process.env.REACT_APP_CLIENT_API_KEY;

  if (!customersEndpoint) {
    console.error("Customers endpoint not provided!");
    return [];
  }

  if (!clientAPIKey) {
    console.error("Client api key not provided!");
    return [];
  }

  var headers = {};
  headers["API-KEY"] = clientAPIKey;

  try {
    const response = await axios.get(customersEndpoint, { headers: headers });
    return response.data; // Retorna los datos de los clientes
  } catch (error) {
    console.error("Error fetching customers:", error);
    return [];
  }
}

/**
 * Resolves the current customer based on the domain of the current window location.
 * @returns {Object|null} The customer object that matches the current domain or null if no match is found.
 */
export async function resolveCustomer() {
  const currentDomain = window.location.hostname.toLowerCase(); // Normalize to lowercase
  const availableCustomers = await getAvailableCustomers();

  // Find the customer object with a domain that matches the current domain
  const customer = availableCustomers.find(
    (customer) =>
      customer.domains.some((domain) => domain.toLowerCase() === currentDomain) // Normalize to lowercase
  );

  // Return the customer object if a match is found, otherwise return null
  storeResolvedCustomer(customer ? customer : null);
  return customer ? customer : null;
}

/**
 * Stores the resolved customer object in local storage.
 * @param {Object} customer - The customer object to store.
 * @returns {boolean} True if the customer is stored successfully, false otherwise.
 */
export function storeResolvedCustomer(customer) {
  if (customer) {
    localStorage.setItem("resolvedCustomer", JSON.stringify(customer));
  } else {
    localStorage.removeItem("resolvedCustomer");
  }

  return true;
}

/**
 * Retrieves the resolved customer object from local storage.
 * @returns {Object|null} The customer object if it exists in local storage, otherwise null.
 */
export function getResolvedCustomer() {
  var customerJson = "";

  customerJson = localStorage.getItem("resolvedCustomer");

  return customerJson ? JSON.parse(customerJson) : null;
}
/**
 * Resolves the backend API endpoint URL for the matched customer.
 * @returns {string|null} The API endpoint URL if a match is found, otherwise null.
 */
export function resolveBackendUrl() {
  const customer = getResolvedCustomer();
  // Return the backend URL if a match is found, otherwise return null
  return customer ? customer.api_endpoint : "http://crm305.com:8000/api";
}

/**
 * Resolves a specific key's value from the matched customer's configuration.
 * @param {string} key - The key to retrieve from the matched customer object.
 * @returns {any|null} The value of the specified key if a match is found, otherwise null.
 */
export function resolveSystemConfiguredCustomersKey(key) {
  const customer = getResolvedCustomer();
  // Return the key's value if a match is found, otherwise return null
  return customer && customer[key] ? customer[key] : null;
}

/**
 * Fetches general system settings from the backend and invokes a callback with the fetched data.
 * @param {Array} settings - The list of setting names to fetch.
 * @param {Function} callBack - The callback function to execute with the fetched data.
 * @returns {Promise<boolean|null>} True if the settings are fetched and callback executed successfully, false if an error occurs, null otherwise.
 */
export async function fetchGeneralSystemSettings(settings, callBack) {
  try {
    const ApiLib = await import("../lib/api");

    const Api = ApiLib.default;

    const { data } = await Api.getGeneralSystemConfigs(settings);
    if (data?.data) {
      // var decryptedData = {};
      // await Promise.all(
      //   Object.keys(data.data).forEach(async (key) => {
      //     decryptedData[key] = await decryptData(
      //       data.data[key],
      //       process.env.REACT_APP_SYSTEM_CONFIGS_PRIVATE_ENCRYPTION_KEY
      //     );
      //   })
      // );

      callBack(data.data);
      return true;
    }
  } catch (err) {
    console.error("Error fetching config: ", err);
    return false;
  }
  return null;
}

/**
 * Stores system configuration data in local storage.
 * @param {Object} systemConfig - The system configuration object to store.
 * @returns {boolean} True if the configuration is stored successfully, false otherwise.
 */
export function storeSystemConfig(systemConfig) {
  if (systemConfig) {
    localStorage.setItem("systemConfig", JSON.stringify(systemConfig));

    return true;
  }
  localStorage.removeItem("systemConfig");
  return false;
}

/**
 * Retrieves a specific system configuration setting from local storage.
 * @param {string} setting - The name of the setting to retrieve.
 * @returns {any} The value of the specified setting.
 */
export function getSystemConfigSetting(setting) {
  const settingValueJson = localStorage.getItem("systemConfig");
  var settingValue = settingValueJson
    ? (JSON.parse(settingValueJson) || {})[setting] || null
    : null;

  if (settingValue) {
    settingValue = decryptData(
      settingValue,
      process.env.REACT_APP_SYSTEM_CONFIGS_PRIVATE_ENCRYPTION_KEY
    );
  }

  return settingValue;
}

export function decryptData(encryptedDataBase64, privateKeyPem) {
  try {
    // Decrypt the base64-encoded data using the private key and PKCS1 padding
    const decryptedData = crypto.privateDecrypt(
      {
        key: privateKeyPem,
        padding: crypto.constants.RSA_PKCS1_PADDING, // PKCS#1 v1.5 padding
      },
      Buffer.from(encryptedDataBase64, "base64")
    );

    // Return the decrypted data as a UTF-8 string
    return decryptedData.toString("utf8");
  } catch (error) {
    console.error("Error decrypting data:", error);
  }
}

/**
 * Retrieves the current domain of the frontend, including the protocol.
 * @returns {string} The current domain with protocol.
 */
export function getCurrentDomainWithProtocol() {
  const protocol = window.location.protocol; // 'http:' or 'https:'
  const host = window.location.host; // e.g., 'localhost:3000' or 'example.com'
  return `${protocol}//${host}`;
}
