import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import {
  fetchGeneralSystemSettings,
  resolveCustomer,
  resolveSystemConfiguredCustomersKey,
  storeResolvedCustomer,
  storeSystemConfig,
} from "./helpers/systemconfigs";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ReactDOM from "react-dom";

const Loading = () => (
  <Backdrop style={{ color: "#fff" }} open={true}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

const AlertMessage = () => (
  <Alert
    severity="warning"
    style={{ textAlign: "center", border: "1px solid #e9dd88" }}
  >
    <Typography variant="body2" style={{ fontWeight: 500 }}>
      This application is not properly configured, please contact support.
    </Typography>
  </Alert>
);

const initializeApp = async () => {
  try {
    storeResolvedCustomer(null);
    ReactDOM.render(<Loading />, document.getElementById("root"));
    var customer = await resolveCustomer();

    if (customer) {
      // const pageIcon = resolveSystemConfiguredCustomersKey("page_icon");
      // if (pageIcon) {
      //   const favicon = document.getElementById("page-icon-header");
      //   if (favicon) {
      //     favicon.href = pageIcon;
      //   }

      //   const appleIcon = document.getElementById("page-icon-header-apple");
      //   if (appleIcon) {
      //     appleIcon.href = pageIcon;
      //   }
      // }

      function saveSystemConfig(settings) {
        var currentConfiguration =
          JSON.parse(localStorage.getItem("systemConfig")) || {};

        storeSystemConfig({ ...currentConfiguration, ...settings });
      }

      await fetchGeneralSystemSettings([], saveSystemConfig);
      const MainFile = await import("./main");

      MainFile.renderApp();
    } else {
      ReactDOM.render(<AlertMessage />, document.getElementById("root"));
    }
  } catch (error) {
    console.error("Error loading data or rendering app", error);
  }
};

initializeApp();
